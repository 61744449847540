var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      tag: "component",
      class: `${_vm.className} _${_vm.id}`,
      attrs: {
        "data-tippy-content": _vm.content,
        "data-tippy-placement": _vm.position,
      },
    },
    [
      _vm._t("default"),
      _vm.type === "template"
        ? _c("emd-tippy-template", {
            attrs: {
              id: _vm.id,
              title: _vm.title,
              description: _vm.description,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }