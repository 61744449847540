var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "emd-onboarding__greeting eu-mb_4" }, [
    _c(
      "div",
      { staticClass: "emd-container _footer_centered _footer_end_md" },
      [
        _c(
          "div",
          {
            staticClass:
              "emd-container__header eu-height_auto eu-justify_end eu-align-items_start",
          },
          [
            _c(
              "a",
              {
                staticClass: "emd-btn _color_contrast _type_link",
                attrs: { href: "javascript:void(0)" },
                on: { click: _vm.close },
              },
              [_c("i", { staticClass: "uil uil-times" })]
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "emd-container__content" },
          [
            _c("div", { staticClass: "emd-onboarding__image" }),
            _c("h2", { staticClass: "emd-text _size_page" }, [
              _vm._v(" " + _vm._s(_vm.$t("hello")) + " "),
            ]),
            _c("p", { staticClass: "emd-text eu-my_2" }, [
              _vm._v(_vm._s(_vm.$t("messageOne"))),
            ]),
            _c(
              "i18n",
              {
                staticClass: "emd-text _color_primary eu-my_2",
                attrs: { path: "messageTwo", tag: "p" },
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "emd-link _color_primary",
                    attrs: { to: { name: "accountProduction" } },
                  },
                  [_vm._v(_vm._s(_vm.$t("accountTab")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "emd-container__footer" }, [
          _c(
            "button",
            {
              staticClass: "emd-btn _color_tertiary",
              on: { click: _vm.close },
            },
            [_vm._v(" " + _vm._s(_vm.$t("button")) + " ")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }