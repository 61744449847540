import { Read } from './read'
import { ModuleDefault } from '../default'
import { ISetUp, IPageParams } from '@dto'

export class ChargesModule extends ModuleDefault {
    private _read: Read

    // Read
    public getAll: (merchantId: string, params?: IPageParams) => Promise<any>
    public getById: (merchantId: string, chargeId: string) => any

    constructor(setup: ISetUp) {
        super(setup)
        this._read = new Read(setup)

        this.getAll = this._read.getAll
        this.getById = this._read.getById
    }
}
